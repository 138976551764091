// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

:root {
  /* Colors */
  --basico-branco: #ffffff;
  --basico-preto: #030312;
  --basico-cinza-1: #21272a;
  --basico-cinza-2: #697077;
  --light-amarelo-light: #fff6cc;
  --light-verde-light: #ddf7d5;
  --light-creme-light: #fefcf9;
  --institucional-azul-azul-1: #00044d;
  --institucional-azul-azul-2: #0205ac;
  --institucional-azul-azul-3: #81a3f0;
  --institucional-azul-azul-4: #ecf3fb;
  --institucional-amarelo-amarelo-1: #ffa500;
  --institucional-amarelo-amarelo-2: #fed000;
  --institucional-creme-creme-1: #e5d3be;
  --institucional-creme-creme-2: #efe5d8;
  --institucional-creme-creme-3: #faf0e3;
  --campanhas-cura: #062cf6;
  --campanhas-futuro: #00b0ff;
  --campanhas-prote-o: #00cfff;
  --campanhas-liberdade: #8800c2;
  --campanhas-natureza: #56d72b;
  --campanhas-conhecimento: #fed000;
  --campanhas-semente: #ffa500;
  --campanhas-empatia: #fd6925;
  --campanhas-luta: #ff0077;
  --campanhas-dignidade: #ff0038;
  --campanhas-preto: #2d2d2d;
  --campanhas-creme: #faf0e3;
  --mat-sidenav-content-text-color: #212121;
  /* Fonts */

  /* Effects */
  --azul-16-x-40-x-20-box-shadow: 0px 16px 40px 0px rgba(12, 0, 96, 0.2);
  --azul-8-x-24-x-15-box-shadow: 0px 8px 24px 0px rgba(12, 0, 96, 0.15);
  --azul-2-box-shadow: 0px 8px 24px 0px rgba(129, 163, 240, 0.15);
}



$primary: (
  50 : #e3e4ea,
  100 : #b8bccb,
  200 : #898fa8,
  300 : #596285,
  400 : #36406b,
  500 : #121e51,
  600 : #101a4a,
  700 : #0d1640,
  800 : #0a1237,
  900 : #050a27,
  A100 : #ecf3fb,
  A200 : #81a3f0,
  A400 : #0205ac,
  A700 : #00044d,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$accent: (
  50 : #fff4e0,
  100 : #ffe4b3,
  200 : #ffd280,
  300 : #ffc04d,
  400 : #ffb326,
  500 : #ffa500,
  600 : #ff9d00,
  700 : #ff9300,
  800 : #ff8a00,
  900 : #ff7900,
  A100 : #efe5d8,
  A200 : #e5d3be,
  A400 : #fed000,
  A700 : #ffa500,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dia_de_doar-primary: mat.define-palette($primary);
$dia_de_doar-accent: mat.define-palette($accent);

// The warn palette is optional (defaults to red).
$dia_de_doar-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dia_de_doar-theme: mat.define-light-theme((color: (primary: $dia_de_doar-primary,
        accent: $dia_de_doar-accent,
        warn: $dia_de_doar-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($dia_de_doar-theme);

:root {
  .mdc-text-field {
    border: solid 1px;
    border-radius: 8px !important;
    height: 50px;
  }
}